<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Modifica Utente</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Modifica Utente</ion-title>
        </ion-toolbar>
      </ion-header>  
      <div id="container">
        <form @submit="submit">
          <label>Username:</label>
          <ion-input id="input" v-model="user.name"></ion-input>
          <label>E-mail:</label>
          <ion-input id="input" type="email" v-model="user.email"></ion-input>
          <label>Company:</label>
          <ion-select id="input" placeholder="Seleziona la company di riferimento" v-model="user.company">
            <ion-select-option :value="c.name" v-for="c,i in companies" :key="'account-'+i">{{c.name}}</ion-select-option>
          </ion-select>
          <label>Livello:</label>
          <ion-select placeholder="Seleziona il livello.." v-model="user.role">
            <ion-select-option :value="r" v-for="r,i in ruoli" :key="'role-'+i">{{roles[i]}}</ion-select-option>
          </ion-select>
          <label>Reimposta password:</label> <img style="width:25px;height:22px;margin-top:5px;" src="images\showpwd.png" @click="viewpwd=!viewpwd">
          <ion-input id="input" type="password" v-model="user.password" v-if="viewpwd==false" @onkeypress="modpwd=1"></ion-input> 
          <ion-input id="input" v-model="user.password" v-if="viewpwd==true"  @onkeypress="modpwd=1"></ion-input> 
          <ion-button type="submit">MODIFICA</ion-button> 
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent, 
    IonHeader,
    IonInput, 
    IonMenuButton, 
    IonPage,
    IonSelect,
    IonSelectOption, 
    IonTitle, 
    IonToolbar,
    toastController,
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonInput, 
      IonMenuButton,
      IonPage,
      IonSelect,
      IonSelectOption,
      IonTitle,
      IonToolbar
    },
    data: function(){
      return{
        user:{
          name:'',
          email:'',
          role:0,
          company:'',
          password:'',
        },
        ruoli:[0,1,2,3,4,5,6,7],
        roles:['Cliente','Consulente','Interinale','Stagista','Dipendente','Admin','Company admin','Superadmin'],
        companies:[],
        viewpwd:false,
        modpwd:'',
      }
    },
    methods:{
      submit(ev){
        ev.preventDefault();
        if(this.name!='' && this.email!='' && this.company!='' && this.role!=''){
          if(this.password!=''){
            this.axios.post(this.apiUrl + 'update-user', {user:this.user , modpwd:this.modpwd}, this.headers)
            .then(async (response) => {
              if(response.data.status_code==200){
                const toast = await toastController
                .create({
                  color: 'success',
                  message: response.data.message,
                  duration: 2000
                })
                this.$router.push('/folder/users-list');
                return toast.present();
              }
            })
          }else{
             this.axios.post(this.apiUrl + 'update-user-nopwd', {user:this.user}, this.headers)
            .then(async (response) => {
              if(response.data.status_code==200){
                const toast = await toastController
                .create({
                  color: 'success',
                  message: response.data.message,
                  duration: 2000
                })
                this.$router.push('/folder/users-list');
                return toast.present();
              }
            })
          }
        }
      },
      getUserInfo(){
        this.axios.post(this.apiUrl + 'get-user-info', {id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          this.user=response.data.user;
        })
      },
      getCompaniesList(){
        this.axios.post(this.apiUrl + 'get-companies-list', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.companies=response.data.companies            
          }
        })
      },
    },
    ionViewWillEnter: function(){
      this.getUserInfo();
      this.getCompaniesList();
    }
  }
</script>
<style scoped>
  #input{
    background-color:#a9bdff48;
    border-radius:5px;
    margin:5px;
    width:90%;
  }
  form{
      padding:10px;
  }
  ion-select{
      background-color:#a9bdff48;
      border-radius:5px;
      margin:5px;
      width:90%;
    }
</style>